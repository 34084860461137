import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { Link } from "gatsby"
import emailconfirm from "../../images/emailconfirmed.gif"
// import { navigate } from '@reach/router'

const backendUrl = process.env.STRAPI_API_URL
function Redirect(props) {
  React.useEffect(() => {
    // Successfully logged with the provider
    // Now logging with strapi by using the access_token (given by the provider) in props.location.search
    fetch(`${backendUrl}/auth/email-confirmation${props.location.search}`)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login . Status: ${res.status}`)
        }
        return res
      })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        if (res.status === 302 || 200) {
          navigate("/login")
        }
        // Successfully logged with Strapi
        // Now saving the jwt to use it for future authenticated requests to Strapi
        // setText('You have been successfully logged in. You will be redirected in a few seconds...');
        // Redirect to homepage after 3 sec
      })
      .catch((err) => {
        console.log(err)
        // setText('An error occurred, please see the developer console.')
      })
  }, `${props.location.search}`)

  return (
    <Wrapper>
      <div className="email_confirm_wrapper">
        <div className="email_confirm_image">
          <img src={emailconfirm} alt="certisured email confirm" />
        </div>
        <h1 className="email_confirm_h1">
          you've confirmed the email so you can login
        </h1>
        <Link to="/login">log in</Link>
      </div>
    </Wrapper>
  )
}

export default Redirect
const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .email_confirm_wrapper,
  .email_recieved_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .email_confirm_image {
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        @media (min-width: 767px) {
          width: 70%;
        }
      }
    }
    .email_confirm_h1 {
      color: #000 !important;
      text-align: center;
      font-size: 1rem;
      @media (max-width: 479px) {
        font-size: 1rem;
      }
    }
  }

  a {
    background-color: var(--thirdColor);
    color: var(--secondaryColor);
    min-width: 150px;
    padding: 15px 25px;
    outline: none;
    text-decoration: none;
    display: flex;
    justify-content: center;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 500;
  }
  .email_confirm_h2 {
    color: #000 !important;
    text-align: center;
    font-size: 1rem;
    @media (max-width: 479px) {
      font-size: 1rem;
    }
  }
`
